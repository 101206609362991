require('./src/styles/global.css')
require('./src/styles/cabaret.css')
require('./src/styles/location.css')

exports.onPreRouteUpdate = ({ location }) => {
  const tposOverlay = document.getElementById('tpos-layer')
  if (tposOverlay) {
    window.tpos_dialog_close()
  }
}
